import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import Layout from "../../layouts"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SEO from "../../components/seo"
import Footer from "../../components/footer"
import Header from "../../components/header"
import Title from "../../components/hero/title"
import Subtitle from "../../components/hero/subtitle"
import ContentBlock from "../../components/content-block"
import Metaball from "../../components/metaball"

import { subtitleStyles } from "../../utils/styles"
import { getLayout } from "../../utils/layout"
import { mediaQueries, colors, space } from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import LazySVGImg from "../../components/lazy-svg"

const styles = {
  ourStoryContainer: {
    maxWidth: rhythm(24),
    marginTop: space[8],
    h3: {
      ...subtitleStyles,
      fontWeight: 800,
      color: colors.gatsby,
    },
    [mediaQueries.desktop]: {
      marginBottom: space[8],
    },
  },
  profilesContainer: {
    paddingBottom: `12rem`,
  },
  skatingButler: {
    height: 200,
    width: 200,
    position: `absolute`,
    bottom: 0,
    left: `50%`,
    transform: `translateX(-50%)`,
  },
}

class AboutUsPage extends React.Component {
  render() {
    const { contentfulPage, nav } = this.props.data
    const { title, subtitle, contentBlocks } = contentfulPage
    const layout = getLayout(this.props.location.pathname)

    return (
      <Layout
        pathname={this.props.location.pathname}
        background={layout.background}
      >
        <SEO contentfulPage={contentfulPage} />
        <Header navItems={nav.edges} isInverted={layout.inverted} />
        <Container>
          <Metaball fill={colors.white} />
          <HeroContainer>
            <Title customStyles={{ color: colors.gatsby, marginBottom: 0 }}>
              {title}
            </Title>
            {subtitle && (
              <Subtitle customStyles={{ color: colors.gatsby }}>
                {subtitle.subtitle}
              </Subtitle>
            )}
          </HeroContainer>
        </Container>
        {contentBlocks &&
          contentBlocks.map((cb, i) => (
            <Container
              customStyles={{
                paddingBottom: space[8],
                position: `relative`,
                overflow: `hidden`,
                ...(cb.__typename === `ContentfulProfilesBlock`
                  ? { ...styles.profilesContainer }
                  : false),
              }}
              innerCustomStyles={{
                ...(cb.__typename === `ContentfulLongFormBlock`
                  ? { ...styles.ourStoryContainer }
                  : false),
              }}
              key={i}
            >
              {cb.__typename === `ContentfulProfilesBlock` && (
                <LazySVGImg
                  src="about-skating-butler.svg"
                  css={styles.skatingButler}
                />
              )}
              <div css={{ maxWidth: `none` }}>
                <ContentBlock contentBlock={cb} />
              </div>
            </Container>
          ))}
        <Footer navItems={nav.edges} />
      </Layout>
    )
  }
}

AboutUsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default AboutUsPage

export const pageQuery = graphql`
  query ContentfulAboutPage($id: String!) {
    ...MainNavigation
    contentfulPage(id: { eq: $id }) {
      ...ContentBlocks
      ...SocialMediaImage
      title
      subtitle {
        subtitle
      }
    }
  }
`
